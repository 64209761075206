



































































































































































































































































































/**
 * Show missed calls
 */
import Vue from "vue";
import { CallList } from "@/mixins";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import rules from "@/components/account/rules";
import NotRecords from "@/components/NotRecords.vue";
import RecordedCall from "@/models/RecordedCall";
import BtnMakeCall from "./buttons/BtnMakeCall.vue";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import moment from "moment";
import RecordPlayer from "./RecordPlayer.vue";
import { notifyInfo } from "@/components/Notification";
// import TableToolbar from "./TableToolbar.vue";

export default Vue.extend({
  // components: { TableToolbar },
  name: "missed-calls",
  mixins: [CallList],
  components: {
    NotRecords,
    BtnMakeCall,
    MaDatePicker,
    RecordPlayer,
  },
  data() {
    return {
      rules: {
        email: rules.email,
        required: rules.required,
        phone: rules.isPhone,
      },
      showPlayer: false,
      file: "",
      currentRecord: {
        uuid: "66efaa8b-7d8c-4a44-8d94-24aede53f2f7",
        from: "+111111111",
        to: "+122222222",
      },
      dialogConfirmRemove: false,
      footerProps: { "items-per-page-options": [15, 50, 100] },
      options: {},
      filterRange: "year",
      filternumber: null,
      filterpatient: null,
      filteremployee: null,
      reqbody: {
        range: {
          limit: 15,
          offset: 0,
        },
        missed: true,
        dateRange: {
          date1: "",
          date2: "",
        },
        phoneNumber: null,
      },
      dateFrom: "",
      dateTo: "",
      headers: [
        {
          text: this.$t("origin"),
          align: "start",
          sortable: false,
          value: "origin",
        },
        {
          text: this.$t("Name"),
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: this.$t("crmCallCenter.from"),
          align: "start",
          sortable: false,
          value: "from",
        },
        {
          text: this.$t("Name"),
          align: "start",
          sortable: false,
          value: "toname",
        },
        {
          text: this.$t("crmCallCenter.to"),
          align: "start",
          sortable: false,
          value: "to",
        },
        {
          text: this.$t("crmCallCenter.duration"),
          align: "left",
          sortable: false,
          value: "duration",
        },
        {
          text: this.$t("labelDate"),
          align: "center",
          sortable: false,
          value: "createAt",
        },
        { text: "", value: "actions", sortable: false },
      ],
    };
  },
  watch: {
    options(val) {
      if (val != {}) {
        (this as any).getCalls();
      }
    },
    dateFrom(val) {
      if (
        val !== null &&
        (this as any).dateTo !== null &&
        (this as any).dateTo !== ""
      ) {
        (this as any).filterRange = "custom";
        (this as any).getCalls();
      }
    },
    dateTo(val) {
      if (
        val !== null &&
        (this as any).dateFrom !== null &&
        (this as any).dateFrom !== ""
      ) {
        (this as any).filterRange = "custom";
        (this as any).getCalls();
      }
    },

    filternumber(val) {
      if (val != null) {
        (this as any).filterpatient = null;
        (this as any).filteremployee = null;
        if (val.length == 10) {
          (this as any).reqbody.phoneNumber = "+1" + val;
          (this as any).getCalls();
        } else if (val.length == 0) {
          (this as any).reqbody.phoneNumber = null;
          (this as any).getCalls();
        }
      }
    },
    filterpatient(val) {
      if (val != null) {
        (this as any).filternumber = null;
        (this as any).filteremployee = null;
        (this as any).reqbody.phoneNumber = val;
        (this as any).getCalls();
      }
    },
    filteremployee(val) {
      if (val != null) {
        (this as any).filterpatient = null;
        (this as any).filternumber = null;
        (this as any).reqbody.phoneNumber = val;
        (this as any).getCalls();
      }
    },
  },
  destroyed() {
    (this as any).mutRequest(null);
    (this as any).mutFilter(false);
  },
  computed: {
    ...mapGetters([
      "isSuper",
      "isAdmin",
      "getPhone",
      "isSupervisor",
      "getUser",
      "getName",
      "getpatientN",
    ]),
    ...mapState("crmEmployeeModule", {
      loadingEmployees: "loading",
      employees: "workers",
    }),
    ...mapState("crmMedicFormModule", ["patients"]),
    ...mapState(["totalitems", "userContacts"]),
    ...mapState("crmCallCenterModule", [
      "missList",
      "totalmiss",
      "requestC",
      "filter",
    ]),

    workersf() {
      const w = this.employees.filter(
        (e: any) => e.phone != null && e.phone != ""
      );
      return w;
    },
    validClean() {
      if (
        ((this as any).dateFrom != null &&
          (this as any).dateFrom != undefined &&
          (this as any).dateFrom != "") ||
        ((this as any).dateTo != null &&
          (this as any).dateTo != undefined &&
          (this as any).dateTo != "")
      ) {
        return true;
      }
      return false;
    },

    admin() {
      if (this.isSuper || this.isAdmin || this.isSupervisor) {
        return true;
      }
      return false;
    },
    missedcall() {
      if (this.admin) {
        return this.missList;
      }
      const misscall = this.missList.filter(
        (mcall: RecordedCall) =>
          mcall.to == this.getPhone || mcall.to == `client:${this.getUser}`
      );
      return misscall;
    },

    heads() {
      const temp = [
        {
          text: this.$t("origin"),
          align: "start",
          sortable: false,
          value: "origin",
        },
        {
          text: this.$t("name"),
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Number",
          align: "start",
          sortable: false,
          value: "from",
        },
        {
          text: this.$t("crmCallCenter.duration"),
          align: "left",
          sortable: false,
          value: "duration",
        },
        {
          text: this.$t("labelDate"),
          align: "center",
          sortable: false,
          value: "createAt",
        },
        { text: "", value: "actions", sortable: false },
      ];
      return this.admin ? (this as any).headers : temp;
    },
  },
  mounted() {
    if (this.isSuper || this.isAdmin || this.isSupervisor) {
      (this as any).actGetEmployees();
    }

    (this as any).actListPatientsFilter();
  },

  methods: {
    ...mapActions("crmCallCenterModule", [
      "actGetCallsMis",
      "actRemoveRecordedCall",
    ]),
    ...mapMutations("crmCallCenterModule", [
      "mutRequest",
      "mutFilter",
      "mutView",
    ]),
    ...mapActions("crmEmployeeModule", ["actGetEmployees"]),
    ...mapActions("crmMedicFormModule", ["actListPatientsFilter"]),
    ...mapMutations("crmSMSModule", ["mutSetContact"]),
    ...mapMutations(["mutReqRange"]),

    getNameTo(item: any) {
      if (item.to == `client:${item.employee_username}`) {
        return item.employee_fullname;
      }
      if (item.to == item.employee_phone) {
        return item.employee_fullname;
      }
      if (item.patient_phone == item.to) {
        return item.patient_name;
      }
      if (item.l_phone == item.to) {
        return item.l_name;
      }
      return "-";
    },

    closedPlayer() {
      (this as any).file = "";
      (this as any).showPlayer = false;
    },
    playRecord(file: any): void {
      (this as any).file = file.recordUrl;
      (this as any).showPlayer = true;
    },
    itemStatus(call: RecordedCall) {
      if (call.status == "completed") {
        return "mdi-phone-check";
      } else if (call.status == "queued") {
        return "mdi-phone-settings";
      } else if (call.status == "ringing") {
        return "mdi-phone-in-talk";
      } else if (call.status == "canceled") {
        return "mdi-phone-missed";
      } else {
        if (
          call.from == this.getPhone ||
          call.from == `client:${this.getUser}`
        ) {
          return "mdi-phone-forward-outline";
        }
        return "mdi-phone-missed";
      }
    },
    numberShow(call: RecordedCall) {
      if (call.from == this.getPhone || call.from == `client:${this.getUser}`) {
        return call.to;
      } else {
        return call.from;
      }
    },

    _origin(item: any) {
      if (item.from == `client:${item.employee_username}`) {
        return "Employee";
      }

      if (item.from == item.employee_phone) {
        return "Employee";
      }
      if (item.patient_phone == item.from) {
        return "Patient";
      }
      if (item.l_phone == item.from) {
        return "Lead";
      }

      return "-";
    },

    getFrom(call: RecordedCall) {
      if (call.from == null || call.from == undefined) {
        return "-";
      }
      if (call.from == this.getPhone || call.from == `client:${this.getUser}`) {
        return "My selft";
      } else {
        return (this as any).cleanPhone(call.from, call);
      }
    },
    getTo(call: RecordedCall) {
      if (call.to == null || call.to == undefined) {
        return "-";
      }
      if (call.to == this.getPhone || call.to == `client:${this.getUser}`) {
        return "My selft";
      } else {
        return (this as any).cleanPhone(call.to, call);
      }
    },
    _name(item: any) {
      if (item.from == `client:${item.employee_username}`) {
        return item.employee_fullname;
      }

      if (item.from == item.employee_phone) {
        return item.employee_fullname;
      }
      if (item.patient_phone == item.from) {
        return item.patient_name;
      }
      if (item.l_phone == item.from) {
        return item.l_name;
      }

      return "-";
    },

    setContact(item: RecordedCall) {
      const phone = (this as any).numberShow(item);
      const contact = { number: phone, name: phone };
      (this as any).mutSetContact(contact);
      this.$router.push("/chat");
    },

    getCalls() {
      const { page, itemsPerPage }: any = (this as any).options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 15;
      (this as any).reqbody.range = {
        limit: itmper,
        offset: (page - 1) * itmper,
      };
      if ((this as any).filterRange == "year") {
        (this as any).reqbody.dateRange = {
          date1: moment().startOf("year").utc().toISOString(),
          date2: moment().utc().toISOString(),
        };
      } else {
        (this as any).reqbody.dateRange = {
          date1: moment((this as any).dateFrom)
            .startOf("day")
            .utc()
            .toISOString(),
          date2: moment((this as any).dateTo)
            .endOf("day")
            .utc()
            .toISOString(),
        };
      }
      (this as any).actGetCallsMis((this as any).reqbody);
    },

    setRecordToRemove(record: RecordedCall) {
      (this as any).currentRecord = record;
      (this as any).dialogConfirmRemove = true;
    },
    remove() {
      (this as any)
        .actRemoveRecordedCall((this as any).currentRecord.uuid)
        .then(() => {
          (this as any).dialogConfirmRemove = false;
          (this as any).getCalls();
          const en = this.$i18n.locale == "en";
          const bodyEn = "Call deleted successfully";
          const bodyEs = "Llamada eliminada con éxito";

          notifyInfo(en ? bodyEn : bodyEs);
        });
    },
    _clearFilters() {
      (this as any).dateFrom = "";
      (this as any).dateTo = "";
      (this as any).filterRange = "year";
      (this as any).clearFilters();
    },
    clearFilters() {
      (this as any).filternumber = null;
      (this as any).filterpatient = null;
      (this as any).filteremployee = null;
      (this as any).reqbody.phoneNumber = null;
      (this as any).getCalls();
    },
  },
});
